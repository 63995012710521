export const PLUS_FEATURES = [
  {
    title: 'Online access',
    text: `Manage your entire Skylight library of photos and videos on any device.  With a single tap or click, you can upload, share, download, delete, and more.`,
  },
  {
    title: 'Cloud backup',
    text: `With Skylight Plus, your photos & videos are safe, even if something happens to your Skylight Frame. Your family's memories are backed up forever – you can even add them to a new Frame with a single tap.`,
  },
  {
    title: 'Remote settings',
    text: `You can change a Frame's settings straight from the app – It's perfect for helping a loved one customize the gift you gave them. Brightness, sleep schedule, volume, and more are all easy to modify with a tap in the app, no matter where you are.`,
  },
  {
    title: 'Albums',
    text: 'Uploading a ton of photos and videos? Create albums to easily view, organize, and share your photos on demand.',
  },
  {
    title: 'Slideshow mirroring',
    text: `Share photos from the Skylight app straight to your television. Perfect for family visits.`,
  },
  {
    title: 'Doodles',
    text: `Send a Doodle to a Frame, and see it recreated line-by-line in seconds. Receiving a doodle feels like being there with the doodler, like magic.`,
  },
]
